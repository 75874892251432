@import "../app/components/theme-settings.scss";

@media #{$desktop-device} {
    .overflow-lg-auto{
        overflow: auto;
    }
}

@media #{$tablet-device} {
    .overflow-md-auto{
        overflow: auto;
    }
}

.px-64{
    padding-left: 64px;
    padding-right: 64px;
}

.pl-8{
    padding-left: 8px;
}